import React from 'react'
import Document from '../Components/Document/Document';
import ProxyLogin from '../Components/ProxyLogin/ProxyLogin'
import { Helmet } from "react-helmet";

export default function document() {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Prodocs | Documents</title>
                <link rel="canonical" href="/prodocs-logo.png" />
            </Helmet>
            <Document />
            <ProxyLogin popupType={2} />
        </div>
    )
}
