import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Menu from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import Select from "react-select";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Amplify, { API, graphqlOperation } from "aws-amplify";
import config from "../../aws-exports";
import Chat from "../../assets/Chat.svg";
import DocTableRow from "./DocTableRows";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import ToggleIcon from "material-ui-toggle-icon";
import IconButton from "@material-ui/core/IconButton";
import {
  FaSistrix,
  FaEdit,
  FaEye,
  FaArrowDown,
  FaCalculator,
  FaExternalLinkAlt,
  FaEnvelope,
  FaTruck,
} from "react-icons/fa";
import { Table } from "react-bootstrap";
import {
  elasticSearchDeleteDocOrTemp,
  setEsDownFlagDoc,
  getDocumentByID,
  elasticSearchCreateDocOrTemp,
  getDocumentByUserID,
  getTemplate,
  getDocumentByUserIDx,
  getDocumentByUserIDExport,
  getDocumentByTeamID,
  getContactById,
  elasticSearchFetchDoc,
  getDocumentEstimateData,
  getDocumentWorkOrderData,
  generatePdf,
  sendEmail,
  getDocumentsListingElasticSearch,
  elasticSearchUpdateDocOrTemp,
  createAllUserDocsOnElasticsearch
} from "../../graphql/queries";
import { useSelector, useDispatch } from "react-redux";
import { Link, navigate } from "gatsby";
import { MDBInput } from "mdbreact";
import { useCookies } from "react-cookie";
import Cancel from "../../assets/cross.svg";
import Export from "../../assets/export_latest.svg";
import Loader from "./../../assets/loading.gif";
import "../../main.css";
import ReactPaginate from "react-paginate";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { func } from "prop-types";
import { CSVLink } from "react-csv";
import ExportToCsvModal from "../Modals/ExportToCsvModal";
import ToastBox from "../Toast/Toast";
import clearicon from "../../assets/clearicon.svg";
import SendEmailModal from '../Modals/SendEmailModal';
import { calculateData, isEmpty } from '../CommonComponents/helper';
import  secureLocalStorage  from  "react-secure-storage";
import Sync from "./../../assets/sync.gif";
Amplify.configure(config);

export default function Document() {
  const {consoleLog} = require('../commonFunctions.js');
  const dispatch = useDispatch();
  const csvLink = useRef();

  const [documents, setDocuments] = useState([]);
  //pagination
  consoleLog('x>>>',process.env.GATSBY_DEFAULT_LIST_SIZE);
  const [itemsPerPage, setItemsPerPage] = useState(process.env.GATSBY_DEFAULT_LIST_SIZE ? process.env.GATSBY_DEFAULT_LIST_SIZE : 10);
  const [pageDocuments, setPageDocuments] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [show, setShow] = useState(false);
  const [forcedPage, setForcedPage] = useState(null);

  const [synching, setSynching] = useState(false);
  const showSynching = async () => {
        setSynching(true);
        await new Promise((resolve) => setTimeout(resolve, 2000));  // Simulate save delay
        setSynching(false);
  };

  const headers = [
    { label: "Name", key: "name" },
    { label: "Address", key: "address" },
    { label: "Created By", key: "created_by" },
    { label: "Claim / Reference Number", key: "claim_number" },
    { label: "Client Company", key: "company" },
    { label: "Client Name", key: "client_name" },
    { label: "Client Email", key: "email" },
    { label: "Client Phone", key: "phone" },
    { label: "Created Date", key: "date_created" },
    { label: "Last Modified", key: "date_updated" },
  ];

  async function fetchDocuments() {
    let client_id = JSON.parse(secureLocalStorage.getItem("client_data")).client_id;
    let client_idx = client_id;
    let team_id = JSON.parse(secureLocalStorage.getItem("client_data")).team_id;
    let team_role = JSON.parse(secureLocalStorage.getItem("client_data")).team_role;
    let leader_id = JSON.parse(secureLocalStorage.getItem("client_data")).leader_id;
    let client_email = JSON.parse(secureLocalStorage.getItem("client_data")).email;
    
    if (team_role == "Leader" || team_role == "Admin") {
      client_id = 0;
    }
    if ((team_role == '' || team_role == null) && client_id == leader_id) {
      client_id = 0;
    }

    let es_down_docs = JSON.parse(secureLocalStorage.getItem("es_down_docs")).body.docs;
    consoleLog('fetchDocuments es_down_docs>>>', es_down_docs);
    if(es_down_docs.length > 0){
      consoleLog('fetchDocuments es_down_docs>>>', es_down_docs.length);
      for(let i=0; i<es_down_docs.length; i++){
        if(typeof es_down_docs[i].es_down_flag != 'undefined' && es_down_docs[i].es_down_flag == '2'){ //2 for create
          showSynching();
          const template_record = await API.graphql({
            query: elasticSearchFetchDoc,
            variables: {
                doc_id: es_down_docs[i].doc_id
            }
          });
          let template_status = JSON.parse(JSON.parse(template_record.data.elasticSearchFetchDoc)).status;
          consoleLog('fetchDocuments es_down_docs template_status>>>', template_status);
          if(template_status == 500){
            break;
          }
          if(template_status == 400){
            let get_doc_data;

            try {
              showSynching();
              get_doc_data = await API.graphql({
                query: getDocumentByID,
                variables: {
                    doc_id: es_down_docs[i].doc_id
                }
              });
            } catch (err) {
              console.error(err);
              break;
            }
            
            let doc = {...get_doc_data.data.getDocumentByID};
       
            doc.invoice_pdf = "";
            doc.quantities = null;
            doc.status = 1;
            doc.create_on_elasticsearch = 1;
            consoleLog('fetchDocuments es_down_docs get_doc_data>>>', JSON.stringify(doc));
            
            try {
              showSynching();
              const save_elastic_es = await API.graphql({
                  query: elasticSearchCreateDocOrTemp,
                  variables: {
                      id: es_down_docs[i].doc_id,
                      index: 'prodocs-documents',
                      template: JSON.stringify(doc)
                  }
              });
              consoleLog('fetchDocuments es_down_doc save_elastic_es', save_elastic_es);
            } catch (err) {
                console.error(err);
            }
            let es_down_docx = JSON.parse(secureLocalStorage.getItem("es_down_docs")).body.docs;  
            let temp_docs = es_down_docx.filter(d =>d.doc_id != es_down_docs[i].doc_id);
            consoleLog('fetchDocuments es_down_doc es_down_docs[i].doc_id>>>', es_down_docs[i].doc_id);
            consoleLog('fetchDocuments es_down_doc temp_docs>>>', temp_docs);

            let temp_docs_temp = JSON.parse(secureLocalStorage.getItem("es_down_docs"));
            consoleLog('fetchDocuments es_down_doc temp_docs_temp>>>', temp_docs_temp);
            temp_docs_temp.body.docs = temp_docs;
            consoleLog('fetchDocuments es_down_doc temp_docs_temp final>>>', temp_docs_temp);
            secureLocalStorage.setItem('es_down_docs', JSON.stringify(temp_docs_temp));

            const set_flag_off = API.graphql({
              query: setEsDownFlagDoc,
              variables: {
                  doc_id: es_down_docs[i].doc_id,
                  flag: '0'
              }
            });
            consoleLog('fetchDocuments es_down_doc set_flag_off>>>', set_flag_off);
          }
        }
        if(typeof es_down_docs[i].es_down_flag != 'undefined' && es_down_docs[i].es_down_flag == '3'){ //3 for delete
          consoleLog('fetchDocuments es_down_doc delete>>>', es_down_docs[i]);
          showSynching();
          const template_record = await API.graphql({ //check if elasticseach is down
            query: elasticSearchFetchDoc,
            variables: {
                doc_id: es_down_docs[i].doc_id
            }
          });
          let template_status = JSON.parse(JSON.parse(template_record.data.elasticSearchFetchDoc)).status;
          consoleLog('fetchDocuments es_down_docs template_status>>>', template_status);
          if(template_status == 500){
            break;
          }
          showSynching();
          const delete_es_doc = await API.graphql({ // delete from elasticsearch
            query: elasticSearchDeleteDocOrTemp,
            variables: {
              id: es_down_docs[i].doc_id,
              index: 'prodocs-documents'
            }
          });
          consoleLog('fetchDocuments es_down_doc delete_es_doc>>>', delete_es_doc);

          let es_down_docx = JSON.parse(secureLocalStorage.getItem("es_down_docs")).body.docs;
          let temp_docs = es_down_docx.filter(d =>d.doc_id != es_down_docs[i].doc_id);
          consoleLog('fetchDocuments es_down_doc temp_docs>>>', temp_docs);

          let temp_docs_temp = JSON.parse(secureLocalStorage.getItem("es_down_docs"));
          consoleLog('fetchDocuments es_down_doc temp_docs_temp>>>', temp_docs_temp);
          temp_docs_temp.body.docs = temp_docs;
          consoleLog('fetchDocuments es_down_doc temp_docs_temp final>>>', temp_docs_temp);
          secureLocalStorage.setItem('es_down_docs', JSON.stringify(temp_docs_temp));
        }
      }
    }
    showSynching();
    const es_doc_templates = await API.graphql({
      query: getDocumentsListingElasticSearch,
      variables: {
        user_id: client_id,
        team_id: team_id,
        limit: itemsPerPage,
        offset: 0,
        name_location: inputSearch,
        doc_type_id: filterDocType,
        ref_claim_num: inputClaimNumber,
        created_date_start: inputCDateFrom,
        created_date_end: inputCDateTo,
        updated_date_start: inputMDateFrom,
        updated_date_end: inputMDateTo,
      },
    });
    consoleLog('fetchDocuments client_id>',client_id);
    consoleLog('fetchDocuments team_id>',team_id);
    consoleLog('fetchDocuments team_role>',team_role);
    consoleLog('fetchDocuments leader_id>',leader_id);
    consoleLog('fetchDocuments itemsPerPage>',itemsPerPage);
    consoleLog('fetchDocuments inputSearch>',inputSearch);
    consoleLog('fetchDocuments filterDocType>',filterDocType);
    consoleLog('fetchDocuments inputClaimNumber>',inputClaimNumber);
    consoleLog('fetchDocuments inputCDateFrom>',inputCDateFrom);
    consoleLog('fetchDocuments inputCDateTo>',inputCDateTo);
    consoleLog('fetchDocuments inputMDateFrom>',inputMDateFrom);
    consoleLog('fetchDocuments inputMDateTo>',inputMDateTo);
    let e_doc_data = JSON.parse(es_doc_templates.data.getDocumentsListingElasticSearch);
    consoleLog('fetchDocuments e_doc_data>',e_doc_data);
    let e_doc_datax = e_doc_data.data;
    consoleLog('fetchDocuments e_doc_datax>',e_doc_datax);
    let doc_count = e_doc_data.count;
    consoleLog('fetchDocuments doc_count>', doc_count);
    let doc_status = e_doc_data.status;
    consoleLog('fetchDocuments doc_status>', doc_status);
    let doc_data = [];
    if(e_doc_datax.length < 1){
      showSynching();
      const doc_template = await API.graphql({
        query: getDocumentByUserIDx,
        variables: {
          user_id: client_id,
          team_id: team_id,
          limit: itemsPerPage,
          offset: 0,
          name_location: inputSearch,
          doc_type: filterDocType,
          ref_claim_num: inputClaimNumber,
          created_date_start: inputCDateFrom,
          created_date_end: inputCDateTo,
          updated_date_start: inputMDateFrom,
          updated_date_end: inputMDateTo,
        },
      });
      let temp_data = JSON.parse(doc_template.data.getDocumentByUserIDx);
      consoleLog("fetchDocuments data", temp_data.data);
      consoleLog("fetchDocuments count", temp_data.count.count);
      doc_data = temp_data.data;
      doc_count = temp_data.count.count;
      consoleLog('fetchDocuments get from DB...',doc_data);
      /// since we have got the documents now lets create documents on elasticsearch
      if(doc_data.length > 0 && doc_status == 200){
        const create_doc_es = API.graphql({
          query: createAllUserDocsOnElasticsearch,
          variables: {
            user_id: client_idx,
            team_id: team_id,
            doc_type_id: 0,
            start_date: '',
            end_date: '',
            email: client_email
          },
        });
      }
    }else{
      doc_data = e_doc_datax.map(function(element){
        return element._source;
      });
      consoleLog('fetchDocuments get from ES...',doc_data);
    }

    consoleLog('fetchDocuments doc_data>',doc_data);

    setPageDocuments(doc_data);
    setPageCount(doc_count);
    setLoading(false);
  }

  const [state, setState] = useState({ on: false });
  const [state2, setState2] = useState({ on: false });
  const [state3, setState3] = useState({ on: false });
  const [state4, setState4] = useState({ on: false });
  const [state5, setState5] = useState({ on: false });
  const [state6, setState6] = useState({ on: false });
  const [selectedValue, setSelectedValue] = useState("");
  const [byName, setByName] = useState("");

  const [showdivT, setShowdivT] = React.useState(false);
  const onClickT = () => setShowdivT(!showdivT);

  const [expandAdvanceOptions, setExpandAdvanceOptions] = React.useState(false);

  const [expandDocType, setExpandDocType] = React.useState(false);
  function onClickDocType() {
    setExpandDocType(!expandDocType);
    setState2((state2) => ({ on: !state2.on }));
  }

  const [expandNameAddress, setExpandNameAddress] = React.useState(false);
  function onClickNameAddress() {
    setExpandNameAddress(!expandNameAddress);
    setState3((state3) => ({ on: !state3.on }));
  }

  const [expandReference, setExpandReference] = React.useState(false);
  function onClickReference() {
    setExpandReference(!expandReference);
    setState4((state4) => ({ on: !state4.on }));
  }

  const [expandCreatedDate, setExpandCreatedDate] = React.useState(false);
  function onClickCreatedDate() {
    setExpandCreatedDate(!expandCreatedDate);
    setState5((state5) => ({ on: !state5.on }));
  }

  const [expandLastModified, setExpandLastModified] = React.useState(false);
  function onClickLastModified() {
    setExpandLastModified(!expandLastModified);
    setState6((state6) => ({ on: !state6.on }));
  }

  const [loading, setLoading] = useState(true);
  const [isActive, setActive] = useState("false");
  const [isSearch, setIsSearch] = useState("N");
  const [searchData, setSearchData] = useState([]);
  const [inputSearch, setInputSearch] = useState("");
  const [filterDocType, setFilterDocType] = useState(0);
  const [inputClaimNumber, setInputClaimNumber] = useState("");
  const [inputCDateFrom, setInputCDateFrom] = useState("");
  const [inputCDateTo, setInputCDateTo] = useState("");
  const [inputMDateFrom, setInputMDateFrom] = useState("");
  const [inputMDateTo, setInputMDateTo] = useState("");

  const [inputCDateFromx, setInputCDateFromx] = useState("");
  const [inputCDateTox, setInputCDateTox] = useState("");
  const [inputMDateFromx, setInputMDateFromx] = useState("");
  const [inputMDateTox, setInputMDateTox] = useState("");
  const [documentsList, setDocumentsList] = useState([]);
  const [documentLoading, setDocumentLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [showOrangeBtn, setShowOrangeBtn] = useState(false);
  const [showClearButton, setShowClearButton] = useState(false);

  const ToggleClass = () => {
    setActive(!isActive);
  };
  const clearInputHandler = () => {
    setShowClearButton(false);
    resetAllFilters();
  };
  const inputFocusHandler = () => {
    setShowOrangeBtn(true);
  };
  const inputBlurHandler = () => {
    setShowOrangeBtn(false);
  };
  function onClickAdvanceOptionDD() {
    setExpandAdvanceOptions(!expandAdvanceOptions);
    setState((state) => ({ on: !state.on }));
    setActive(!isActive);
  }
  async function handlePageChange(data) {
    let client_id = JSON.parse(secureLocalStorage.getItem("client_data")).client_id;
    let team_id = JSON.parse(secureLocalStorage.getItem("client_data")).team_id;
    let team_role = JSON.parse(secureLocalStorage.getItem("client_data")).team_role;
    let leader_id = JSON.parse(secureLocalStorage.getItem("client_data")).leader_id;

    if (team_role == "Leader" || team_role == "Admin") {
      client_id = 0;
    }
    if ((team_role == '' || team_role == null) && client_id == leader_id) {
      client_id = 0;
    }

    let newOffset = itemsPerPage * data.selected;
    setForcedPage(data.selected);
    setItemOffset(newOffset);
    consoleLog("handlePageChange forcedPage", forcedPage);
    consoleLog("handlePageChange data.selected>", data.selected);
    consoleLog("handlePageChange offset>", newOffset);
    consoleLog('handlePageChange client_id>',client_id);
    consoleLog('handlePageChange team_role>',team_role);
    consoleLog('handlePageChange team_id>',team_id);
    consoleLog('handlePageChange leader_id>',leader_id);
    consoleLog('handlePageChange itemsPerPage>',itemsPerPage);
    consoleLog('handlePageChange inputSearch>',inputSearch);
    consoleLog('handlePageChange filterDocType>',filterDocType);
    consoleLog('handlePageChange inputClaimNumber>',inputClaimNumber);
    consoleLog('handlePageChange inputCDateFrom>',inputCDateFrom);
    consoleLog('handlePageChange inputCDateTo>',inputCDateTo);
    consoleLog('handlePageChange inputMDateFrom>',inputMDateFrom);
    consoleLog('handlePageChange inputMDateTo>',inputMDateTo);
    showSynching();
    const es_doc_templates = await API.graphql({
      query: getDocumentsListingElasticSearch,
      variables: {
        user_id: client_id,
        team_id: team_id,
        limit: itemsPerPage,
        offset: newOffset,
        name_location: inputSearch,
        doc_type_id: filterDocType,
        ref_claim_num: inputClaimNumber,
        created_date_start: inputCDateFrom,
        created_date_end: inputCDateTo,
        updated_date_start: inputMDateFrom,
        updated_date_end: inputMDateTo,
      },
    });

    let e_doc_data = JSON.parse(es_doc_templates.data.getDocumentsListingElasticSearch);
    let e_doc_datax = e_doc_data.data;
    consoleLog('handlePageChange e_doc_datax>',e_doc_datax);
    consoleLog('handlePageChange e_doc_data.count>',e_doc_data.count);

    if(e_doc_datax.length < 1){
      consoleLog('handlePageChange get from DB...',e_doc_datax.length);
      showSynching();
      const doc_template = await API.graphql({
        query: getDocumentByUserIDx,
        variables: {
          user_id: client_id,
          team_id: team_id,
          limit: itemsPerPage,
          offset: newOffset,
          name_location: inputSearch,
          doc_type: filterDocType,
          ref_claim_num: inputClaimNumber,
          created_date_start: inputCDateFrom,
          created_date_end: inputCDateTo,
          updated_date_start: inputMDateFrom,
          updated_date_end: inputMDateTo,
        },
      });
      let temp_data = JSON.parse(doc_template.data.getDocumentByUserIDx);
      consoleLog("handlePageChange data", temp_data.data);
      consoleLog("handlePageChange count", temp_data.count);
      setPageDocuments(temp_data.data);
    }else{
      let doc_data = e_doc_datax.map(function(element){
        return element._source;
      });

      setPageDocuments(doc_data);
      //no need to set page count here
    }
  }
  var page = "document";

  async function handleSearchInput(e) {
    setInputSearch(e.target.value);

    setForcedPage(0);
    setItemOffset(0);

    if (e.target.value === "") {
      setShowClearButton(false);
    } else {
      setShowClearButton(true);
    }
    let client_id = JSON.parse(secureLocalStorage.getItem("client_data")).client_id;
    let team_id = JSON.parse(secureLocalStorage.getItem("client_data")).team_id;
    let team_role = JSON.parse(secureLocalStorage.getItem("client_data")).team_role;
    let leader_id = JSON.parse(secureLocalStorage.getItem("client_data")).leader_id;

    if (team_role == "Leader" || team_role == "Admin") {
      client_id = 0;
    }
    if ((team_role == '' || team_role == null) && client_id == leader_id) {
      client_id = 0;
    }
    showSynching();
    const es_doc_templates = await API.graphql({
      query: getDocumentsListingElasticSearch,
      variables: {
        user_id: client_id,
        team_id: team_id,
        limit: itemsPerPage,
        offset: 0,
        name_location: e.target.value,
        doc_type_id: filterDocType,
        ref_claim_num: inputClaimNumber,
        created_date_start: inputCDateFrom,
        created_date_end: inputCDateTo,
        updated_date_start: inputMDateFrom,
        updated_date_end: inputMDateTo,
      },
    });

    consoleLog('handleSearchInput forcedPage>',forcedPage);
    consoleLog('handleSearchInput client_id>',client_id);
    consoleLog('handleSearchInput team_id>',team_id);
    consoleLog('handleSearchInput team_role>',team_role);
    consoleLog('handleSearchInput leader_id>',leader_id);
    consoleLog('handleSearchInput itemsPerPage>',itemsPerPage);
    consoleLog('handleSearchInput inputSearch>',e.target.value);
    consoleLog('handleSearchInput filterDocType>',filterDocType);
    consoleLog('handleSearchInput inputClaimNumber>',inputClaimNumber);
    consoleLog('handleSearchInput inputCDateFrom>',inputCDateFrom);
    consoleLog('handleSearchInput inputCDateTo>',inputCDateTo);
    consoleLog('handleSearchInput inputMDateFrom>',inputMDateFrom);
    consoleLog('handleSearchInput inputMDateTo>',inputMDateTo);
    let e_doc_data = JSON.parse(es_doc_templates.data.getDocumentsListingElasticSearch);
    let e_doc_datax = e_doc_data.data;
    consoleLog('handleSearchInput e_doc_datax>',e_doc_datax);
    consoleLog('handleSearchInput e_doc_data.count>',e_doc_data.count);

    if(e_doc_datax.length < 1){
      consoleLog('handleSearchInput get from DB...',e_doc_datax.length);
      showSynching();
      const doc_template = await API.graphql({
        query: getDocumentByUserIDx,
        variables: {
          user_id: client_id,
          team_id: team_id,
          limit: itemsPerPage,
          offset: 0,
          name_location: e.target.value,
          doc_type: filterDocType,
          ref_claim_num: inputClaimNumber,
          created_date_start: inputCDateFrom,
          created_date_end: inputCDateTo,
          updated_date_start: inputMDateFrom,
          updated_date_end: inputMDateTo,
        },
      });
      let temp_data = JSON.parse(doc_template.data.getDocumentByUserIDx);
      consoleLog("handleSearchInput data", temp_data.data);
      consoleLog("handleSearchInput count", temp_data.count.count);
      setPageDocuments(temp_data.data);
      setPageCount(temp_data.count.count);
    }else{
      let doc_data = e_doc_datax.map(function(element){
        return element._source;
      });
      consoleLog('handleSearchInput doc_data>',doc_data);

      setPageDocuments(doc_data);
      setPageCount(e_doc_data.count);
    }
  }

  async function handleDoctype(e) {
    let val = 1 * e.target.value;
    setFilterDocType(val);

    let client_id = JSON.parse(secureLocalStorage.getItem("client_data")).client_id;
    let team_id = JSON.parse(secureLocalStorage.getItem("client_data")).team_id;
    let team_role = JSON.parse(secureLocalStorage.getItem("client_data")).team_role;
    let leader_id = JSON.parse(secureLocalStorage.getItem("client_data")).leader_id;

    if (team_role == "Leader" || team_role == "Admin") {
      client_id = 0;
    }
    if ((team_role == '' || team_role == null) && client_id == leader_id) {
      client_id = 0;
    }

    setForcedPage(0);
    setItemOffset(0);
    showSynching();
    const es_doc_templates = await API.graphql({
      query: getDocumentsListingElasticSearch,
      variables: {
        user_id: client_id,
        team_id: team_id,
        limit: itemsPerPage,
        offset: 0,
        name_location: inputSearch,
        doc_type_id: val,
        ref_claim_num: inputClaimNumber,
        created_date_start: inputCDateFrom,
        created_date_end: inputCDateTo,
        updated_date_start: inputMDateFrom,
        updated_date_end: inputMDateTo,
      },
    });

    consoleLog('handleDoctype client_id>',client_id);
    consoleLog('handleDoctype team_id>',team_id);
    consoleLog('handleDoctype team_role>',team_role);
    consoleLog('handleDoctype leader_id>',leader_id);
    consoleLog('handleDoctype itemsPerPage>',itemsPerPage);
    consoleLog('handleDoctype inputSearch>',inputSearch);
    consoleLog('handleDoctype filterDocType>',val);
    consoleLog('handleDoctype inputClaimNumber>',inputClaimNumber);
    consoleLog('handleDoctype inputCDateFrom>',inputCDateFrom);
    consoleLog('handleDoctype inputCDateTo>',inputCDateTo);
    consoleLog('handleDoctype inputMDateFrom>',inputMDateFrom);
    consoleLog('handleDoctype inputMDateTo>',inputMDateTo);
    let e_doc_data = JSON.parse(es_doc_templates.data.getDocumentsListingElasticSearch);
    let e_doc_datax = e_doc_data.data;
    consoleLog('handleDoctype e_doc_datax>',e_doc_datax);
    consoleLog('handleDoctype e_doc_data.count>',e_doc_data.count);

    if(e_doc_datax.length < 1){
      consoleLog('handleDoctype get from DB...',e_doc_datax.length);
      showSynching();
      const doc_template = await API.graphql({
        query: getDocumentByUserIDx,
        variables: {
          user_id: client_id,
          team_id: team_id,
          limit: itemsPerPage,
          offset: 0,
          name_location: inputSearch,
          doc_type: val,
          ref_claim_num: inputClaimNumber,
          created_date_start: inputCDateFrom,
          created_date_end: inputCDateTo,
          updated_date_start: inputMDateFrom,
          updated_date_end: inputMDateTo,
        },
      });
      let temp_data = JSON.parse(doc_template.data.getDocumentByUserIDx);
      consoleLog("handleDoctype data", temp_data.data);
      consoleLog("handleDoctype count", temp_data.count.count);
      setPageDocuments(temp_data.data);
      setPageCount(temp_data.count.count);
    }else{
      let doc_data = e_doc_datax.map(function(element){
        return element._source;
      });
      consoleLog('handleDoctype doc_data>',doc_data);

      setPageDocuments(doc_data);
      setPageCount(e_doc_data.count);
    }
  }

  async function handleClaimNumber(e) {
    setInputClaimNumber(e.target.value);
    let client_id = JSON.parse(secureLocalStorage.getItem("client_data")).client_id;
    let team_id = JSON.parse(secureLocalStorage.getItem("client_data")).team_id;
    let team_role = JSON.parse(secureLocalStorage.getItem("client_data")).team_role;
    let leader_id = JSON.parse(secureLocalStorage.getItem("client_data")).leader_id;

    if (team_role == "Leader" || team_role == "Admin") {
      client_id = 0;
    }
    if ((team_role == '' || team_role == null) && client_id == leader_id) {
      client_id = 0;
    }

    setForcedPage(0);
    setItemOffset(0);
    showSynching();
    const es_doc_templates = await API.graphql({
      query: getDocumentsListingElasticSearch,
      variables: {
        user_id: client_id,
        team_id: team_id,
        limit: itemsPerPage,
        offset: 0,
        name_location: inputSearch,
        doc_type_id: filterDocType,
        ref_claim_num: e.target.value,
        created_date_start: inputCDateFrom,
        created_date_end: inputCDateTo,
        updated_date_start: inputMDateFrom,
        updated_date_end: inputMDateTo,
      },
    });

    consoleLog('handleClaimNumber client_id>',client_id);
    consoleLog('handleClaimNumber team_id>',team_id);
    consoleLog('handleClaimNumber team_role>',team_role);
    consoleLog('handleClaimNumber leader_id>',leader_id);
    consoleLog('handleClaimNumber itemsPerPage>',itemsPerPage);
    consoleLog('handleClaimNumber inputSearch>',inputSearch);
    consoleLog('handleClaimNumber filterDocType>',filterDocType);
    consoleLog('handleClaimNumber inputClaimNumber>',e.target.value);
    consoleLog('handleClaimNumber inputCDateFrom>',inputCDateFrom);
    consoleLog('handleClaimNumber inputCDateTo>',inputCDateTo);
    consoleLog('handleClaimNumber inputMDateFrom>',inputMDateFrom);
    consoleLog('handleClaimNumber inputMDateTo>',inputMDateTo);
    let e_doc_data = JSON.parse(es_doc_templates.data.getDocumentsListingElasticSearch);
    let e_doc_datax = e_doc_data.data;
    consoleLog('handleClaimNumber e_doc_datax>',e_doc_datax);
    consoleLog('handleClaimNumber e_doc_data.count>',e_doc_data.count);

    if(e_doc_datax.length < 1){
      consoleLog('handleClaimNumber get from DB...',e_doc_datax.length);
      showSynching();
      const doc_template = await API.graphql({
        query: getDocumentByUserIDx,
        variables: {
          user_id: client_id,
          team_id: team_id,
          limit: itemsPerPage,
          offset: 0,
          name_location: inputSearch,
          doc_type: filterDocType,
          ref_claim_num: e.target.value,
          created_date_start: inputCDateFrom,
          created_date_end: inputCDateTo,
          updated_date_start: inputMDateFrom,
          updated_date_end: inputMDateTo,
        },
      });
      let temp_data = JSON.parse(doc_template.data.getDocumentByUserIDx);
      consoleLog("handleClaimNumber data", temp_data.data);
      consoleLog("handleClaimNumber count", temp_data.count.count);
      setPageDocuments(temp_data.data);
      setPageCount(temp_data.count.count);
    }else{
      let doc_data = e_doc_datax.map(function(element){
        return element._source;
      });
      consoleLog('handleClaimNumber doc_data>',doc_data);

      setPageDocuments(doc_data);
      setPageCount(e_doc_data.count);
    }
  }

  const formatDate = (date, type) => {
    let d = new Date(date);
    d.setDate(d.getDate());
    // if (type == "start") {
    //   d.setDate(d.getDate() - 1);
    // } else {
    //   d.setDate(d.getDate() + 1);
    // }

    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    let year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    let datex = [year, month, day].join("-");
    let time = " 00:00:00";

    return datex;
  };

  async function handleCreatedDateFrom(e) {
    var c_date_from = formatDate(e, "start");
    consoleLog("handleCreatedDateFrom e", e);
    consoleLog("handleCreatedDateFrom c_date_from>", c_date_from);
    setInputCDateFrom(c_date_from);
    setInputCDateFromx(e);
    if (inputCDateTo) {
      let client_id = JSON.parse(secureLocalStorage.getItem("client_data")).client_id;
      let team_id = JSON.parse(secureLocalStorage.getItem("client_data")).team_id;
      let team_role = JSON.parse(secureLocalStorage.getItem("client_data")).team_role;
      let leader_id = JSON.parse(secureLocalStorage.getItem("client_data")).leader_id;

      setForcedPage(0);
      setItemOffset(0);

      if (team_role == "Leader" || team_role == "Admin") {
        client_id = 0;
      }
      if ((team_role == '' || team_role == null) && client_id == leader_id) {
        client_id = 0;
      }
      showSynching();
      const es_doc_templates = await API.graphql({
        query: getDocumentsListingElasticSearch,
        variables: {
          user_id: client_id,
          team_id: team_id,
          limit: itemsPerPage,
          offset: 0,
          name_location: inputSearch,
          doc_type_id: filterDocType,
          ref_claim_num: inputClaimNumber,
          created_date_start: c_date_from,
          created_date_end: inputCDateTo,
          updated_date_start: inputMDateFrom,
          updated_date_end: inputMDateTo,
        },
      });
  
      consoleLog('handleCreatedDateFrom client_id>',client_id);
      consoleLog('handleCreatedDateFrom team_id>',team_id);
      consoleLog('handleCreatedDateFrom team_role>',team_role);
      consoleLog('handleCreatedDateFrom leader_id>',leader_id);
      consoleLog('handleCreatedDateFrom itemsPerPage>',itemsPerPage);
      consoleLog('handleCreatedDateFrom inputSearch>',inputSearch);
      consoleLog('handleCreatedDateFrom filterDocType>',filterDocType);
      consoleLog('handleCreatedDateFrom inputClaimNumber>',inputClaimNumber);
      consoleLog('handleCreatedDateFrom inputCDateFrom>',c_date_from);
      consoleLog('handleCreatedDateFrom inputCDateTo>',inputCDateTo);
      consoleLog('handleCreatedDateFrom inputMDateFrom>',inputMDateFrom);
      consoleLog('handleCreatedDateFrom inputMDateTo>',inputMDateTo);
      let e_doc_data = JSON.parse(es_doc_templates.data.getDocumentsListingElasticSearch);
      let e_doc_datax = e_doc_data.data;
      consoleLog('handleCreatedDateFrom e_doc_datax>',e_doc_datax);
      consoleLog('handleCreatedDateFrom e_doc_data.count>',e_doc_data.count);

      if(e_doc_datax.length < 1){
        consoleLog('handleCreatedDateFrom get from DB...',e_doc_datax.length);
        showSynching();
        const doc_template = await API.graphql({
          query: getDocumentByUserIDx,
          variables: {
            user_id: client_id,
            team_id: team_id,
            limit: itemsPerPage,
            offset: 0,
            name_location: inputSearch,
            doc_type: filterDocType,
            ref_claim_num: inputClaimNumber,
            created_date_start: c_date_from,
            created_date_end: inputCDateTo,
            updated_date_start: inputMDateFrom,
            updated_date_end: inputMDateTo,
          },
        });
        let temp_data = JSON.parse(doc_template.data.getDocumentByUserIDx);
        consoleLog("handleCreatedDateFrom data", temp_data.data);
        consoleLog("handleCreatedDateFrom count", temp_data.count.count);
        setPageDocuments(temp_data.data);
        setPageCount(temp_data.count.count);
      }else{
        let doc_data = e_doc_datax.map(function(element){
          return element._source;
        });
        consoleLog('handleCreatedDateFrom doc_data>',doc_data);
    
        setPageDocuments(doc_data);
        setPageCount(e_doc_data.count);
      }
    }
  }

  async function handleCreatedDateTo(e) {
    var c_date_to = formatDate(e, "end");
    consoleLog("handleCreatedDateTo e", e);
    consoleLog("handleCreatedDateTo c_date_to>", c_date_to);
    setInputCDateTo(c_date_to);
    setInputCDateTox(e);
    if (inputCDateFrom) {
      let client_id = JSON.parse(secureLocalStorage.getItem("client_data")).client_id;
      let team_id = JSON.parse(secureLocalStorage.getItem("client_data")).team_id;
      let team_role = JSON.parse(secureLocalStorage.getItem("client_data")).team_role;
      let leader_id = JSON.parse(secureLocalStorage.getItem("client_data")).leader_id;

      if (team_role == "Leader" || team_role == "Admin") {
        client_id = 0;
      }
      if ((team_role == '' || team_role == null) && client_id == leader_id) {
        client_id = 0;
      }

      setForcedPage(0);
      setItemOffset(0);
      showSynching();
      const es_doc_templates = await API.graphql({
        query: getDocumentsListingElasticSearch,
        variables: {
          user_id: client_id,
          team_id: team_id,
          limit: itemsPerPage,
          offset: 0,
          name_location: inputSearch,
          doc_type_id: filterDocType,
          ref_claim_num: inputClaimNumber,
          created_date_start: inputCDateFrom,
          created_date_end: c_date_to,
          updated_date_start: inputMDateFrom,
          updated_date_end: inputMDateTo,
        },
      });
  
      consoleLog('handleCreatedDateTo client_id>',client_id);
      consoleLog('handleCreatedDateTo team_role>',team_role);
      consoleLog('handleCreatedDateTo team_id>',team_id);
      consoleLog('handleCreatedDateTo leader_id>',leader_id);
      consoleLog('handleCreatedDateTo itemsPerPage>',itemsPerPage);
      consoleLog('handleCreatedDateTo inputSearch>',inputSearch);
      consoleLog('handleCreatedDateTo filterDocType>',filterDocType);
      consoleLog('handleCreatedDateTo inputClaimNumber>',inputClaimNumber);
      consoleLog('handleCreatedDateTo inputCDateFrom>',inputCDateFrom);
      consoleLog('handleCreatedDateTo inputCDateTo>',c_date_to);
      consoleLog('handleCreatedDateTo inputMDateFrom>',inputMDateFrom);
      consoleLog('handleCreatedDateTo inputMDateTo>',inputMDateTo);
      let e_doc_data = JSON.parse(es_doc_templates.data.getDocumentsListingElasticSearch);
      let e_doc_datax = e_doc_data.data;
      consoleLog('handleCreatedDateTo e_doc_datax>',e_doc_datax);
      consoleLog('handleCreatedDateTo e_doc_data.count>',e_doc_data.count);

      if(e_doc_datax.length < 1){
        consoleLog('handleCreatedDateTo get from DB...',e_doc_datax.length);
        showSynching();
        const doc_template = await API.graphql({
          query: getDocumentByUserIDx,
          variables: {
            user_id: client_id,
            team_id: team_id,
            limit: itemsPerPage,
            offset: 0,
            name_location: inputSearch,
            doc_type: filterDocType,
            ref_claim_num: inputClaimNumber,
            created_date_start: inputCDateFrom,
            created_date_end: c_date_to,
            updated_date_start: inputMDateFrom,
            updated_date_end: inputMDateTo,
          },
        });
        let temp_data = JSON.parse(doc_template.data.getDocumentByUserIDx);
        consoleLog("handleCreatedDateTo data", temp_data.data);
        consoleLog("handleCreatedDateTo count", temp_data.count.count);
        setPageDocuments(temp_data.data);
        setPageCount(temp_data.count.count);
      }else{
        let doc_data = e_doc_datax.map(function(element){
          return element._source;
        });
        consoleLog('handleCreatedDateTo doc_data>',doc_data);
    
        setPageDocuments(doc_data);
        setPageCount(e_doc_data.count);
      }
    } else {
      setShow(true);
    }
  }

  async function handleModifiedDateFrom(e) {
    var m_date_from = formatDate(e, "start");
    setInputMDateFrom(m_date_from);
    consoleLog("handleModifiedDateFrom e>", e);
    consoleLog("handleModifiedDateFrom m_date_from>", m_date_from);

    setInputMDateFromx(e);
    if (inputMDateTo) {
      let client_id = JSON.parse(secureLocalStorage.getItem("client_data")).client_id;
      let team_id = JSON.parse(secureLocalStorage.getItem("client_data")).team_id;
      let team_role = JSON.parse(secureLocalStorage.getItem("client_data")).team_role;
      let leader_id = JSON.parse(secureLocalStorage.getItem("client_data")).leader_id;

      if (team_role == "Leader" || team_role == "Admin") {
        client_id = 0;
      }
      if ((team_role == '' || team_role == null) && client_id == leader_id) {
        client_id = 0;
      }

      setForcedPage(0);
      setItemOffset(0);
      showSynching();
      const es_doc_templates = await API.graphql({
        query: getDocumentsListingElasticSearch,
        variables: {
          user_id: client_id,
          team_id: team_id,
          limit: itemsPerPage,
          offset: 0,
          name_location: inputSearch,
          doc_type_id: filterDocType,
          ref_claim_num: inputClaimNumber,
          created_date_start: inputCDateFrom,
          created_date_end: inputCDateTo,
          updated_date_start: m_date_from,
          updated_date_end: inputMDateTo,
        },
      });
  
      consoleLog('handleModifiedDateFrom client_id>',client_id);
      consoleLog('handleModifiedDateFrom team_id>',team_id);
      consoleLog('handleModifiedDateFrom team_role>',team_role);
      consoleLog('handleModifiedDateFrom leader_id>',leader_id);
      consoleLog('handleModifiedDateFrom itemsPerPage>',itemsPerPage);
      consoleLog('handleModifiedDateFrom inputSearch>',inputSearch);
      consoleLog('handleModifiedDateFrom filterDocType>',filterDocType);
      consoleLog('handleModifiedDateFrom inputClaimNumber>',inputClaimNumber);
      consoleLog('handleModifiedDateFrom inputCDateFrom>',inputCDateFrom);
      consoleLog('handleModifiedDateFrom inputCDateTo>',inputCDateTo);
      consoleLog('handleModifiedDateFrom inputMDateFrom>',m_date_from);
      consoleLog('handleModifiedDateFrom inputMDateTo>',inputMDateTo);
      let e_doc_data = JSON.parse(es_doc_templates.data.getDocumentsListingElasticSearch);
      let e_doc_datax = e_doc_data.data;
      consoleLog('handleModifiedDateFrom e_doc_datax>',e_doc_datax);
      consoleLog('handleModifiedDateFrom e_doc_data.count>',e_doc_data.count);

      if(e_doc_datax.length < 1){
        consoleLog('handleModifiedDateFrom get from DB...',e_doc_datax.length);
        showSynching();
        const doc_template = await API.graphql({
          query: getDocumentByUserIDx,
          variables: {
            user_id: client_id,
            team_id: team_id,
            limit: itemsPerPage,
            offset: 0,
            name_location: inputSearch,
            doc_type: filterDocType,
            ref_claim_num: inputClaimNumber,
            created_date_start: inputCDateFrom,
            created_date_end: inputCDateTo,
            updated_date_start: m_date_from,
            updated_date_end: inputMDateTo,
          },
        });
        let temp_data = JSON.parse(doc_template.data.getDocumentByUserIDx);
        consoleLog("handleModifiedDateFrom data", temp_data.data);
        consoleLog("handleModifiedDateFrom count", temp_data.count.count);
        setPageDocuments(temp_data.data);
        setPageCount(temp_data.count.count);
      }else{
        let doc_data = e_doc_datax.map(function(element){
          return element._source;
        });
        consoleLog('handleModifiedDateFrom doc_data>',doc_data);
    
        setPageDocuments(doc_data);
        setPageCount(e_doc_data.count);
      }
    }
  }

  async function handleModifiedDateTo(e) {
    var m_date_to = formatDate(e, "end");
    consoleLog("handleModifiedDateTo m_date_to>", m_date_to);

    setInputMDateTo(m_date_to);
    setInputMDateTox(e);
    if (inputMDateFrom) {
      let client_id = JSON.parse(secureLocalStorage.getItem("client_data")).client_id;
      let team_id = JSON.parse(secureLocalStorage.getItem("client_data")).team_id;
      let team_role = JSON.parse(secureLocalStorage.getItem("client_data")).team_role;
      let leader_id = JSON.parse(secureLocalStorage.getItem("client_data")).leader_id;

      if (team_role == "Leader" || team_role == "Admin") {
        client_id = 0;
      }
      if ((team_role == '' || team_role == null) && client_id == leader_id) {
        client_id = 0;
      }

      setForcedPage(0);
      setItemOffset(0);
      showSynching();
      const es_doc_templates = await API.graphql({
        query: getDocumentsListingElasticSearch,
        variables: {
          user_id: client_id,
          team_id: team_id,
          limit: itemsPerPage,
          offset: 0,
          name_location: inputSearch,
          doc_type_id: filterDocType,
          ref_claim_num: inputClaimNumber,
          created_date_start: inputCDateFrom,
          created_date_end: inputCDateTo,
          updated_date_start: inputMDateFrom,
          updated_date_end: m_date_to,
        },
      });
  
      consoleLog('handleModifiedDateTo client_id>',client_id);
      consoleLog('handleModifiedDateTo team_id>',team_id);
      consoleLog('handleModifiedDateTo team_role>',team_role);
      consoleLog('handleModifiedDateTo leader_id>',leader_id);
      consoleLog('handleModifiedDateTo itemsPerPage>',itemsPerPage);
      consoleLog('handleModifiedDateTo inputSearch>',inputSearch);
      consoleLog('handleModifiedDateTo filterDocType>',filterDocType);
      consoleLog('handleModifiedDateTo inputClaimNumber>',inputClaimNumber);
      consoleLog('handleModifiedDateTo inputCDateFrom>',inputCDateFrom);
      consoleLog('handleModifiedDateTo inputCDateTo>',inputCDateTo);
      consoleLog('handleModifiedDateTo inputMDateFrom>',inputMDateFrom);
      consoleLog('handleModifiedDateTo inputMDateTo>',m_date_to);
      let e_doc_data = JSON.parse(es_doc_templates.data.getDocumentsListingElasticSearch);
      let e_doc_datax = e_doc_data.data;
      consoleLog('handleModifiedDateTo e_doc_datax>',e_doc_datax);
      consoleLog('handleModifiedDateTo e_doc_data.count>',e_doc_data.count);

      if(e_doc_datax.length < 1){
        consoleLog('handleModifiedDateTo get from DB...',e_doc_datax.length);
        showSynching();
        const doc_template = await API.graphql({
          query: getDocumentByUserIDx,
          variables: {
            user_id: client_id,
            team_id: team_id,
            limit: itemsPerPage,
            offset: 0,
            name_location: inputSearch,
            doc_type: filterDocType,
            ref_claim_num: inputClaimNumber,
            created_date_start: inputCDateFrom,
            created_date_end: inputCDateTo,
            updated_date_start: inputMDateFrom,
            updated_date_end: m_date_to,
          },
        });
        let temp_data = JSON.parse(doc_template.data.getDocumentByUserIDx);
        consoleLog("handleModifiedDateTo data", temp_data.data);
        consoleLog("handleModifiedDateTo count", temp_data.count.count);
        setPageDocuments(temp_data.data);
        setPageCount(temp_data.count.count);
      }else{
        let doc_data = e_doc_datax.map(function(element){
          return element._source;
        });
        consoleLog('handleModifiedDateTo doc_data>',doc_data);
    
        setPageDocuments(doc_data);
        setPageCount(e_doc_data.count);
      }
    } else {
      setShow(true);
    }
  }

  const redirectDoc = () => {
    navigate("/createdoc");
  };

  async function resetAllFilters() {
    consoleLog("resetAllFilters");
    setInputSearch("");
    setFilterDocType(0);
    setInputClaimNumber("");
    setInputCDateFrom("");
    setInputCDateFromx("");
    setInputCDateTo("");
    setInputCDateTox("");
    setInputMDateFrom("");
    setInputMDateFromx("");
    setInputMDateTo("");
    setInputMDateTox("");
    setShowClearButton(false);
    let client_id = JSON.parse(secureLocalStorage.getItem("client_data")).client_id;
    let team_id = JSON.parse(secureLocalStorage.getItem("client_data")).team_id;
    let team_role = JSON.parse(secureLocalStorage.getItem("client_data")).team_role;
    let leader_id = JSON.parse(secureLocalStorage.getItem("client_data")).leader_id;

    if (team_role == "Leader" || team_role == "Admin") {
      client_id = 0;
    }
    if ((team_role == '' || team_role == null) && client_id == leader_id) {
      client_id = 0;
    }

    setForcedPage(0);
    setItemOffset(0);
    showSynching();
    const es_doc_templates = await API.graphql({
      query: getDocumentsListingElasticSearch,
      variables: {
        user_id: client_id,
        team_id: team_id,
        limit: itemsPerPage,
        offset: 0,
        name_location: "",
        doc_type_id: 0,
        ref_claim_num: "",
        created_date_start: "",
        created_date_end: "",
        updated_date_start: "",
        updated_date_end: ""
      },
    });

    let e_doc_data = JSON.parse(es_doc_templates.data.getDocumentsListingElasticSearch);
    let e_doc_datax = e_doc_data.data;
    consoleLog('resetAllFilters e_doc_datax>',e_doc_datax);
    consoleLog('resetAllFilters e_doc_data.count>',e_doc_data.count);

    if(e_doc_datax.length < 1){
      consoleLog('resetAllFilters get from DB...',e_doc_datax.length);
      showSynching();
      const doc_template = await API.graphql({
        query: getDocumentByUserIDx,
        variables: {
          user_id: client_id,
          team_id: team_id,
          limit: itemsPerPage,
          offset: 0,
          name_location: "",
          doc_type: 0,
          ref_claim_num: "",
          created_date_start: "",
          created_date_end: "",
          updated_date_start: "",
          updated_date_end: "",
        },
      });
      let temp_data = JSON.parse(doc_template.data.getDocumentByUserIDx);
      consoleLog("resetAllFilters data", temp_data.data);
      consoleLog("resetAllFilters count", temp_data.count.count);
      setPageDocuments(temp_data.data);
      setPageCount(temp_data.count.count);
    }else{
      let doc_data = e_doc_datax.map(function(element){
        return element._source;
      });
      consoleLog('resetAllFilters doc_data>',doc_data);

      setPageDocuments(doc_data);
      setPageCount(e_doc_data.count);
    }
  }

  const exportToCsv = () => {
    setModalShow(true);
  };

  const getUsers = async (rows) => {
    if (!documentLoading) {
      setDocumentLoading(true);
      setModalShow(false);
      let client_id = JSON.parse(secureLocalStorage.getItem("client_data")).client_id;
      let team_id = JSON.parse(secureLocalStorage.getItem("client_data")).team_id;
      showSynching();
      const doc_template = await API.graphql({
        query: getDocumentByUserIDExport,
        variables: {
          user_id: client_id,
          team_id: team_id,
          limit: parseInt(rows) > 0 ? parseInt(rows) : 1000,
        },
      });
      consoleLog(doc_template.data.getDocumentByUserIDExport);
      let doc_data = JSON.parse(doc_template.data.getDocumentByUserIDExport);

      setDocumentsList(doc_data);
      csvLink.current.link.click();
      setDocumentLoading(false);
    }
  };

  const [download, setDownload] = useState(false);
  const [docSend, setDocSend] = useState(false);
  const [showMsg, setShowMsg] = useState(false);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const [error, setError] = useState('');
  const [emailPopupShow, setEmailPopupShow] = useState(false);             
  const [pdfUrl, setPdfUrl] = useState('');

  async function downloadPdf(docId, docTypeId, invoice, sendMessage, pdf) {

    consoleLog('downloadPdf ', docId, docTypeId, invoice, sendMessage, pdf);
    
    if(isEmpty(pdf)) {
      setDownload(true);

      let data = {}; 
      let estimateData = {};
      let contactInfo = [];
      let ownerInfo = [];
      showSynching();
      const templateRecordElastic = await API.graphql({
          query: elasticSearchFetchDoc,
          variables: {
              doc_id: docId
          }
      });
      
      estimateData = JSON.parse(JSON.parse(templateRecordElastic.data.elasticSearchFetchDoc)).data;

      consoleLog('downloadPdf es:', templateRecordElastic);
      
      if(Object.keys(estimateData).length == 0 || estimateData?.create_on_elasticsearch == 1) {
        if(docTypeId === 1) {
          showSynching();
          const docRecord = await API.graphql({
              query: getDocumentEstimateData,
              variables: {
                  doc_id: docId
              }
          });
        
          estimateData = JSON.parse(docRecord.data.getDocumentEstimateData);
        }else if(docTypeId === 2) {
          showSynching();
          const templateRecord = await API.graphql({
              query: getDocumentWorkOrderData,
              variables: {
                  doc_id: docId
              }
          });
        
          estimateData = JSON.parse(templateRecord.data.getDocumentWorkOrderData);
        }
      }

      estimateData.doc_id = docId;

      let calcualatedData = calculateData(estimateData);

      if(calcualatedData.totalQuantity <= 0) {
        return navigate(`/template?template_id=${estimateData.template_id}&doc_type_id=${estimateData.doc_type_id}&doc_id=${estimateData.doc_id}`, { state: { error: true } });
      }

      if(estimateData.contact_info) {
        consoleLog("contact info from elastic search.....");
        contactInfo = JSON.parse(estimateData.contact_info);
        contactInfo.zone_name = contactInfo && contactInfo.name ? contactInfo.name : '';
      }else {
        if(typeof estimateData.contact_id != 'undefined' && estimateData.contact_id != '' && estimateData.contact_id != null && estimateData.contact_id != 0){
          showSynching();
          const getContact = await API.graphql({
            query: getContactById,
            variables: {
              contact_id: estimateData.contact_id
            }
          });
    
          const contact = JSON.parse(getContact.data.getContactById);
          contactInfo = contact && contact[0];
        }
      }
  
      if(estimateData.property_owner_info) {
        consoleLog("property owner info from elastic search.....");
        ownerInfo = JSON.parse(estimateData.property_owner_info);
      }else {
        if(typeof estimateData.owner_contact_id != 'undefined' && estimateData.owner_contact_id != '' && estimateData.owner_contact_id != null && estimateData.owner_contact_id != 0){
          showSynching();
          const getPropertyOwner = await API.graphql({
            query: getContactById,
            variables: {
              contact_id: estimateData.owner_contact_id
            }
          });
    
          const propertyOwner = JSON.parse(getPropertyOwner.data.getContactById);
          propertyOwner[0].name = propertyOwner && propertyOwner[0] ? `${propertyOwner[0].first_name} ${propertyOwner[0].last_name}` : '';
          ownerInfo = propertyOwner && propertyOwner[0];
        }
      }

      data.propertyOwner = ownerInfo;
      data.contact = contactInfo;
      data.docDetail = estimateData;
      data.calcualatedData = calculateData(estimateData);
      data.invoice = invoice;
      data.client_default_data = JSON.parse(secureLocalStorage.getItem("client_data"));
      showSynching();
      const response = await API.graphql({
        query: generatePdf,
          variables: {
            docData: JSON.stringify(data)
          }
      }); 
      const getUrl = JSON.parse(response.data.generatePdf)
      pdf = getUrl.message.Location;
      
      estimateData.pdf = pdf;
      
      let temp = JSON.stringify(estimateData);

      API.graphql({
        query: elasticSearchUpdateDocOrTemp,
        variables: {
          id: docId,
          index:'prodocs-documents',
          template:temp
        },
      });

      setDownload(false);
    }

    if(sendMessage === 1){
      setPdfUrl(pdf);
    }else {
      window.open(pdf, "_blank");
    }
  }

  const emailPopup = async (docId, docTypeId, pdf) => {
    setEmailPopupShow(true);
    downloadPdf(docId, docTypeId, 0, 1, pdf);
  }

  async function sendDoc(emails, subject, message, url) { 

    let validEmail = true;
    let toEmails = emails.split(',');
    let client = JSON.parse(secureLocalStorage.getItem('client_data'));

    if(isEmpty(emails) || isEmpty(subject) || isEmpty(message)) {
      setError('Fields with * are required');
    }else if(isEmpty(url)) {
      setError('Something went wrong please try again!');
    }else {
      for (const email of toEmails) {
        if(!(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email.trim()))) {
          validEmail = false;
          break;
        } 
      }
      
      if(validEmail) {
        setDocSend(true);
        setError('');
        showSynching();
        const response = await API.graphql({
          query: sendEmail,
            variables: {
              subject: subject,
              to_email: emails,
              url_download: url,
              email_msg: message,
              client_email: client.email,
              client_id: client.client_id
            }
        }); 

        const result = JSON.parse(response.data.sendEmail);
        if(result.status === 422) {
          setMessage(result.message);
        }
        else if(result[0] && result[0].status === 'sent') {
          setMessage('Your document has been emailed to the requested recipients!');
          setMessageType('Success');
        }else {
          setMessage('We were unable to send the PDF document via email. Please try downloading and emailing the PDF manually.');
          setMessageType('Error');
        }
        setPdfUrl('');
        setShowMsg(true);
        setDocSend(false);
        setEmailPopupShow(false);
      }else {
        setError('Invalid Email address');
      }
    }
  }

  useEffect(() => {
    fetchDocuments();
  }, []);

  return (
    <>
      { modalShow && <ExportToCsvModal
        show={modalShow}
        setModalShow={setModalShow}
        onGetUsers={getUsers}
      /> }
      { emailPopupShow && <SendEmailModal
        show={emailPopupShow}
        setModalShow={setEmailPopupShow}
        error={error}
        docSend={docSend}
        pdfUrl={pdfUrl}
        onSendDoc={sendDoc}
      /> }
      <ToastBox
          setShow={setShowMsg}
          show={showMsg}
          Text={message}
          Type={messageType}
          />
      {!inputCDateFrom ? (
        <ToastBox
          setShow={setShow}
          show={show}
          Text={"Please select start date."}
          Type={'Error'}
        />
      ) : null}
      {!inputMDateFrom ? (
        <ToastBox
          setShow={setShow}
          show={show}
          Text={"Please select start date."}
          Type={'Error'}
        />
      ) : null}

      <Wrapper>
        <Menu page={page} />
        <div className="custom-padding">
          <Row className="wrap pb-0 px-0">
            <div className="search">
              <div className="alpha">
                <div className="alpha-child">
                  <p className="label mb-3">Keyword Search</p>
                  <div className="d-flex position-relative w-100 mt-3 search-block ">
                    <div>
                      <div className="input-container">
                        {showClearButton ? (
                          <img
                            className="clearIconStyling"
                            onClick={clearInputHandler}
                            src={clearicon}
                            alt=""
                          ></img>
                        ) : null}
                        <input
                          type="text"
                          className={`search-input alpha-input ${
                            showClearButton ? "search-input-spacing" : null
                          }`}
                          placeholder="Enter keyword (project name, address, etc.)"
                          onChange={handleSearchInput}
                          onFocus={inputFocusHandler}
                          onBlur={inputBlurHandler}
                          value={inputSearch}
                        />
                        {!expandAdvanceOptions ? null : (
                          <div className="toggleDiv">
                            <div className="d-flex w-100 justify-content-between">
                              <div className="d-flex flex-column w-100">
                                <div
                                  className="type w-100"
                                  style={{ marginTop: "4rem" }}
                                >
                                  <div className="inner-div ">
                                    <p className="text">Document Type</p>
                                    <div className="inputs f-opt">
                                      <div class="custom-control custom-checkbox checkbox-lg">
                                        <input
                                          type="checkbox"
                                          class="custom-control-input"
                                          id="defaultUnchecked"
                                          onClick={handleDoctype}
                                          value="0"
                                          checked={
                                            filterDocType == 0 ? true : false
                                          }
                                        />
                                        <label
                                          class="custom-control-label"
                                          for="defaultUnchecked"
                                        >
                                          Any
                                        </label>
                                      </div>
                                      <div class="custom-control custom-checkbox checkbox-lg">
                                        <input
                                          type="checkbox"
                                          class="custom-control-input"
                                          id="defaultUnchecked1"
                                          onClick={handleDoctype}
                                          value="1"
                                          checked={
                                            filterDocType == 1 ? true : false
                                          }
                                        />
                                        <label
                                          class="custom-control-label"
                                          for="defaultUnchecked1"
                                        >
                                          Estimate
                                        </label>
                                      </div>
                                      <div class="custom-control custom-checkbox checkbox-lg">
                                        <input
                                          type="checkbox"
                                          class="custom-control-input"
                                          id="defaultUnchecked2"
                                          onClick={handleDoctype}
                                          value="2"
                                          checked={
                                            filterDocType == 2 ? true : false
                                          }
                                        />
                                        <label
                                          class="custom-control-label"
                                          for="defaultUnchecked2"
                                        >
                                          Work Order
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="type w-100">
                                  <div className="inner-div">
                                    <p className="text">Name/Address</p>
                                    <div className="inputs">
                                      <input
                                        type="text"
                                        className="search-input w-100"
                                        placeholder="Enter keyword (project name, address, etc.)"
                                        onChange={handleSearchInput}
                                        value={inputSearch}
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="type w-100">
                                  <div
                                    className="inner-div"
                                    onClick={onClickReference}
                                  >
                                    <p className="text">Claim/Ref Number</p>
                                    <div className="inputs">
                                      <input
                                        type="text"
                                        className="search-input w-100"
                                        placeholder="Claim/Reference Number"
                                        onChange={handleClaimNumber}
                                        value={inputClaimNumber}
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="type w-100">
                                  <div
                                    className="inner-div"
                                    onClick={onClickCreatedDate}
                                  >
                                    <p className="text">Created Date</p>
                                    <div className=" d-flex align-items-center justify-content-end inputs">
                                      <DatePicker
                                        className="search-input inputs-double"
                                        placeholderText="mm/dd/yyyy"
                                        selected={inputCDateFromx}
                                        maxDate={inputCDateTox}
                                        onChange={handleCreatedDateFrom}
                                      />
                                      <span
                                        className="mx-3"
                                        style={{ color: "#B6C0C1" }}
                                      >                                        
                                        to
                                      </span>
                                      <DatePicker
                                        className="search-input inputs-double"
                                        placeholderText="mm/dd/yyyy"
                                        selected={inputCDateTox}
                                        minDate={inputCDateFromx}
                                        onChange={handleCreatedDateTo}
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="type w-100">
                                  <div
                                    className="inner-div"
                                    onClick={onClickLastModified}
                                  >
                                    <p className="text">Last Modified</p>
                                    <div className=" d-flex align-items-center justify-content-end inputs">
                                      <DatePicker
                                        className=" search-input inputs-double"
                                        placeholderText="mm/dd/yyyy"
                                        selected={inputMDateFromx}
                                        maxDate={inputMDateTox}
                                        onChange={handleModifiedDateFrom}
                                      />
                                      <span
                                        className="mx-3"
                                        style={{ color: "#B6C0C1" }}
                                      >
                                        to
                                      </span>
                                      <DatePicker
                                        className=" search-input inputs-double"
                                        placeholderText="mm/dd/yyyy"
                                        selected={inputMDateTox}
                                        minDate={inputMDateFromx}
                                        onChange={handleModifiedDateTo}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="reset"
                                onClick={onClickAdvanceOptionDD}
                              >
                                <img style={{width:"26px" }} src={Cancel} alt="" />
                              </div>
                            </div>
                            <div className="bottom-adnanced">
                              {/* <p className="m-0" onClick={resetAllFilters} style={{cursor:"pointer"}}>Reset</p> */}
                              {/* <button
                                className="filter-btn"
                              >
                                Apply Filter
                              </button> */}
                              <button
                                className="filter-btn"
                                onClick={resetAllFilters}
                              >
                                Reset
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="search-button-block">
                      <span
                        className={`${
                          showOrangeBtn ? "orange-btn" : null
                        } search-btn`}
                      >
                        <FaSistrix size={20} />
                      </span>
                      <span className="ad_opt">
                        <div className="advanceOptions">
                          <div className="w-100 d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                              <IconButton
                                style={{ outline: "none", border: "none" }}
                                className="p-1"
                                onClick={onClickAdvanceOptionDD}
                              >
                                <p
                                  className={`${
                                    isActive
                                      ? "advanceOptions"
                                      : "advanceOptions-active"
                                  }
                                  paragraph-styling
                                    `}
                                  onClick={ToggleClass}
                                >
                                  Advanced
                                </p>
                              </IconButton>
                            </div>
                          </div>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>

                <button className="alpha-btn" onClick={redirectDoc}>
                  Create New Doc
                </button>
              </div>
            </div>
          </Row>

          {loading ? (
            <LoadingContainer>
              <img className="loader" src={Loader} />
            </LoadingContainer>
          ) : (
            <div className="TableData wrap">
              {download && <div className="amp_fm_loading">
                  <div className="align_middle"></div>
                  <img className="loader" src={Loader} />			
              </div> }
              <DocTitle className="left-title mb-3">
                <p className="left-title pb-0 mb-0">Documents</p>
                <div className="export">
                  <CSVLink
                    data={documentsList}
                    headers={headers}
                    filename="doc_export.csv"
                    className="hidden"
                    ref={csvLink}
                    target="_blank"
                  />
                  <a className="title mb-0" onClick={exportToCsv}>
                    <span>
                      <img className="exportsize" src={Export} alt="" />
                    </span>
                    <span className="exportTitle">
                    {documentLoading ? "Loading csv..." : "Export to CSV"}
                    </span>
                  </a>
                </div>
              </DocTitle>
              <Table responsive>
                <thead>
                  <tr>
                    <th className="w-20 custom-padding-left-doc">Doc Type</th>
                    <th className="w-35">Name / Address</th>
                    <th className="w-25">Last Modified</th>
                    <th className="w-20"></th>
                  </tr>
                </thead>
                <tbody>
                  {pageDocuments.length > 0 &&
                    pageDocuments.map((data, i) => {
                      return (
                        <>
                          <DocTableRow
                            doc_info={data}
                            key={i}
                            index={i}
                            // isDocActive={isDocActive}
                            // ToggleDocClass={ToggleDocClass}
                            downloadPdf={downloadPdf} 
                            emailPopup={emailPopup}
                          />
                        </>
                      );
                    })}
                  {pageDocuments.length < 1 && (
                    <tr>
                      <td colSpan={4} style={{ textAlign: "center" }}>
                        No document found.
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          )}

          <div className="w-100 d-flex align-items-center justify-content center">
            {pageDocuments.length > 0 && (
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                pageCount={Math.ceil(pageCount / itemsPerPage)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={handlePageChange}
                containerClassName={"pagination justify-content-center"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
                forcePage={forcedPage}
              />
            )}
          </div>
        </div>
        <div className="chat">
          <img src={Chat} alt="" />
        </div>
      </Wrapper>
      <Footer />
      {synching && <img className="sync" src={Sync} />}
    </>
  );
}

const LoadingContainer = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  .loader {
    -webkit-user-select: none;
    margin: auto;
    // background-color: rgba(255,255,255, 0.5);
    // transition: background-color 300ms;
  }
`
const DocTitle = styled.div`
  height: 2.23%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .left-title {
    color: #212e33;
    padding-bottom: 1.22%;
    font-family: Roboto;
    font-size: 28px;
    font-weight: 300;
    letter-spacing: -1.24px;
    line-height: 33px;

    @media (max-width: 600px) {
      font-size: 18px;
      line-height: 24px;
    }
  }

  .export {
    .title {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-family:Rajdhani;
      font-weight: 600;
      text-transform: uppercase;

      &:hover {
        color : #f7941c;
      }

      @media (max-width: 600px) {
        font-size: 14px;
      }
    }
    .exportsize{
      width:26px !important;
      height: 26px !important;
      margin-right: 6px;
    }
  }
`

const TableHead = styled.div`
  height: 1.49%;
  width: 6.53%;
  color: #212e33;
  font-family: Rajdhani;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 22px;
`
const Wrapper = styled.div`
  min-height: 95vh;
  width: 100%;
  background: #f7f9fa;
  position: relative;

  .chat {
    position: absolute;
    bottom: 10px;
    right: 30px;

    @media (max-width: 600px) {
      position: unset;
      display: flex;
      justify-content: end;
      margin-right: 20px;
      margin-bottom: 15px;
    }

    img {
      width: 40px;
      height: auto;
      margin: 10px 0;
    }
  }
  .custom-padding-left-doc {
    padding-left: 10px !important;
  }

  .custom-padding {
    padding: 0 121px 130px 121px;

    @media (max-width: 1024px) {
      padding: 0 50px 100px 50px;
    }

    @media (max-width: 768px) {
      padding: 0 20px;
    }
  }

  .wrap {
    position: relative;
    margin: auto;
    width: 100%;
    min-width: 320px;
    box-sizing: border-box;

    @media (max-width: 600px) {
      margin-top: 3rem;
    }

    .amp_fm_loading {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1000;
      background: rgba(255,255,255,0.8);
      text-align: center;
    }

    .align_middle {
      display: inline-block;
      vertical-align: middle;
      height: 100%;
      width: 0;
      margin-right: -.3em !important;
    }
  }
  .w-15 {
    width: 15% !important;
    padding-left: 20px !important;
  }
  .w-20 {
    width: 20% !important;
  }
  .w-25 {
    width: 25% !important;
    
    @media (max-width: 768px) {
      padding-inline: 20px !important;
    }
  }
  .w-35 {
    width: 35% !important;

    @media (max-width: 768px) {
      padding-inline: 20px !important;
    }
  }
  .w-40 {
    width: 40% !important;
  }

  @media (min-width: 480px) {
    .wrap {
      max-width: 768px;
      padding: 2em 1em;
    }
  }
  @media (min-width: 768px) {
    .wrap {
      max-width: 1024px;
      padding: 2em 0;
    }
  }
  @media (min-width: 1024px) {
    .wrap {
      max-width: 1280px;
      padding: 57px 0px 0 0;
    }
  }
  @media (min-width: 1280px) {
    .xs-hidden,
    .sm-hidden,
    .md-hidden,
    .lg-hidden,
    .xl-hidden,
    .xl-hide {
      display: none !important;
    }
    .wrap {
      max-width: 1280px;
      padding: 57px 0px 0 0;
    }
  }

  .MuiIconButton-root:hover {
    background-color: transparent !important;
  }
  thead {
    height: 2.78%;
    width: 80.63%;
    border-radius: 6px 6px 0 0 !important;
    background-color: #d6e1e5;
    tr {
      height: 41px;
      background: #d6e1e5;
      border-radius: 6px 6px 0 0 !important;
      padding: 0 0 0 20px;
      th {
        height: 41px;
        width: 6.53%;
        color: #212e33;
        font-family: Rajdhani;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 22px;
        text-align: left;
      }
    }
  }
  .estimate {
    height: 30px;
    width: 30px;
    border-radius: 5px;
    margin: 0 8px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f7941d;
  }
  .workorder {
    height: 30px;
    width: 30px;
    border-radius: 5px;
    margin: 0 8px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #9ac8f1;
  }
  .table_icons {
    display: flex;
    align-items: center;
    justify-content: center;

    .table_icons_btn {
      height: 40px;
      width: 40px;
      background: #414141;
      border: none;
      outline: none;
      border-radius: 5px;
      margin: 0 5px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  tbody {
    background: #fff;
    tr {
      td {
        height: 5.15%;
        width: 3.89%;
        color: #212e33;
        font-family: Roboto;
        font-weight: 400;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 18px;
        padding: 20px 0 !important;
        text-align: left;
      }
    }
  }
`
const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 15px;

  .ToggleIcon-root-1 {
    width: 18px;
    height: 18px;
    display: inline-block;
    position: relative;
    color: #212e33;
  }

  .MuiIconButton-root:hover {
    background-color: transparent !important;
  }

  .color-black {
    color: #212e33;
  }
  .bottom-adnanced {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 10px;
    margin: 10px 0;

    p {
      font-size: 16px;
      color: #212e33;
      font-family: Rajdhani;
      text-transform: uppercase;
      font-weight: 600;
    }
  }
  .filter-btn {
    width: 135px;
    height: 44px;
    margin-left: 15px;
    border-radius: 4px;
    padding: 7px;
    background-color: #f7941c;
    text-align: center;
    font-size: 16px;
    text-transform: uppercase;
    color: #212e33;
    font-family: Rajdhani;
    border: none;
    outline: none;
    font-weight: 600;
    cursor: pointer;
    &:hover {
      color: #fff;
      background-color: #000000;
    }
  }
  .search {
    width: 100%;
    margin-bottom: 20px;

    @media (max-width: 600px) {
      margin-bottom: 30px;
    }
    .label {
      font-size: 14px;
      color: #212e33;
      font-weight: 400;
      margin-bottom: 8px;
      font-family: Roboto;
    }
    .alpha {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media (max-width: 968px) {
        flex-direction: column;
        align-items: flex-start;
      }
      .alpha-child {
        @media (max-width: 600px) {
          width: 100%;
        }
      }
      .label {
        margin-bottom: 10px !important;
        font-family: Roboto;
        font-weight: 500;
        color: #212e33;
        padding: 0;
      }

      .alpha-btn {
        height: 44px;
        width: 168px;
        background: #f7941c;
        border-radius: 3px;
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-family: Rajdhani;
        color: #212e33;
        outline: none;
        border: none;
        font-weight: 600;
        text-transform: uppercase;
        &:hover{
          background-color:#000000 !important;
          color:#ffffff !important;
        }
      }
    }

    .search-button-block {
      @media (max-width: 600px) {
        display: flex;
        flex-direction: row-reverse;
        justify-content: end;
        align-items: center;
        margin-top: 5px;
      }
    }
    .search-input {
      height: 44px;
      width: 40%;
      outline: none;
      padding: 10px;
      color: #212e33;
      font-style: normal;
      border-radius: 4px;
      border: 1px solid #b4bebf;
      @media (max-width: 600px) {
        width: 100%;
        position: relative;
      }
      font-style: italic;
    }
    .search-input-spacing {
      padding-left: 35px;
      font-style: normal;
    }

    .search-block {
      @media (max-width: 600px) {
        flex-direction: column;
      }
    }
    .ad_opt {
      position: absolute;
      display: flex;
      align-items: center;
      height: 100%;

      z-index: 99;
      right: 85px;
      top: 0;
      @media (max-width: 600px) {
        position: unset;
        margin: 0 10px 0 0 !important;
        background-color: transparent;
      }
    }
    .paragraph-styling {
      font-size: 14px;
      margin: 0;
    }
    .clearIconStyling {
      position: absolute;
      z-index: 1;
      top: 14px;
      left: 13px;
    }
    /* .advanceOptions{
            position:absolute;
            top:39%;
            right:55%;
            @media(min-width:1428px){
                right:60%;
            }
            @media(max-width:1124px){
                right:40%;
            }
            @media(max-width:768px){
                right:27%;
                top:25%;
            }
            @media(max-width:600px){
                right:3%;
                top: 77%;
            }
        } */
    .alpha-input {
      width: 526px !important;
      position: relative;

      @media (max-width: 1024px) {
        width: 657px !important;
      }

      @media (max-width: 767px) {
        width: 100% !important;
      }
    }
    .search-btn {
      height: 44px;
      width: 44px;
      background: #d6e1e5;
      margin: 0 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      cursor: pointer;

      @media (max-width: 600px) {
        margin: 0px;
      }
    }
    .orange-btn {
      background: #f7941c;
    }
  }

  .reset {
    position: absolute;
    top: 3%;
    right: 5%;
    font-weight: 600;
    img {
      width: 25px;
      height: auto;
      cursor: pointer;
    }
  }

  .toggleDiv {
    min-height: 100px;
    width: 526px;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    align-items: flex-start;
    flex-direction: column;
    font-size: small;
    color: #333;
    position: absolute;
    background: #ffffff;
    z-index: 999;
    left: 0%;
    box-shadow: 0 0 10px rgb(0 0 0 / 20%);

    @media (max-width: 768px) {
      left: 2%;
    }

    @media (max-width: 600px) {
      width: 309px;
      top: 70%;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      /* display: none; <- Crashes Chrome on hover */
      -webkit-appearance: none;
      margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }

    input[type="number"] {
      -moz-appearance: textfield; /* Firefox */
    }

    .custom-control-input:checked ~ .custom-control-label::before {
      border-color: #f7941c;
      background-color: #f7941c;
    }

    .inner-div {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
      font-weight: normal;
      font-family: Roboto;

      @media (max-width: 600px) {
        flex-direction: column;
        align-items: flex-start;
      }

      .text {
        font-size: 14px;
        color: #212e33;
        font-weight: 500;
        font-family: Roboto;
      }
      p {
        margin-bottom: 0 !important;
      }
      .f-opt {
        margin-top: -5px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-family: Roboto;
        .checkbox-lg .custom-control-label {
          cursor: pointer;
        }
    
        .checkbox-lg .custom-control-label::before,
        .checkbox-lg .custom-control-label::after {
          top: 0.8rem;
          width: 1.55rem;
          height: 1.55rem;
          font-weight: normal;
          font-size: 14px;
          color: #212e33;
          font-weight: 500;
        }
        .checkbox-lg .custom-control-label {
          padding-top: 6px;
          padding-left: 6px;
          font-weight: normal;
          font-size: 14px;
          color: #212e33;
          font-weight: 500;
        }
      }
      .inputs {
        width: 75%;

        @media (max-width: 600px) {
          width: 100%;
        }
      }
      .inputs-double {
        width: 100% !important;
      }
    }
  }
`
