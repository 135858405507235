import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import styled from "styled-components";
import Cancel from '../../assets/cross.svg';
import { isEmpty } from '../CommonComponents/helper';
import {Helmet} from "react-helmet";
import "./Modals.css";
import  secureLocalStorage  from  "react-secure-storage";

export default function SendEmailModal(props) {

  let email = '';

  if(typeof window !== 'undefined') { 
    let client = JSON.parse(secureLocalStorage.getItem('client_data'));
    email = client.email;
  }

  const [emails, setEmails] = useState('');
  const [subject, setSubject] = useState(`${email} has sent you a ProDocs document!`);
  const [message, setMessage] = useState('');

  const handleEmails = (value) => {
    setEmails(value);
  } 

  const handleSubject = (value) => {
    setSubject(value);
  }

  const handleMessage = (value) => {
    setMessage(value);
  }

  return (
    <>
     <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <title>Prodocs</title>
      </Helmet>
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{opacity:'1'}}
    >
      <Modal.Header className='pb-0'>
      <img onClick={ () => props.setModalShow(false) } src={Cancel} style={{width: "26px", position: 'absolute', right: '1.5rem', top: '1.5rem' , backgroundColor: 'transparent' , cursor: 'pointer', border: 'none'}}/>
        <Modal.Title id="contained-modal-title-vcenter">
          <p className="modal-title-x mb-0 mt-5 pb-0">Email To...</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-5">
        {props.error && <div class="alert alert-danger" role="alert">
            <strong>Error!</strong> { props.error }
        </div>}
        <p>Add a comma separated list recipient emails to send the PDF document. We will send the generated PDF document to each recipient with your customized subject line and message below.<br/><br/></p>
        <p className="mt-3 my-2">Email Recipients{emails == '' && <span style={{color:"#dc3545"}}> *</span>}</p>
        <InputWrapper>
            <input
              name={"emails"}
              placeholder="joe@email.com,kyle@email.com,helen@email.com"
              className="input-field"
              value={emails}
              onChange={(e) => handleEmails(e.target.value)}
            />
        </InputWrapper> 
        <p className="mt-3 my-2">Email Subject Line{subject == '' && <span style={{color:"#dc3545"}}> *</span>}</p>
        <InputWrapper>
            <input
              name={"subject"}
              placeholder="Hey John, here is your invoice!"
              className="input-field"
              value={subject}
              onChange={(e) => handleSubject(e.target.value)}
            />
        </InputWrapper>
        <p className="mt-3 my-2">Email Message{message == '' && <span style={{color:"#dc3545"}}> *</span>}</p>
        <InputWrapper>
          <textarea
            type="text"
            name={"message"}
            placeholder="Enter a customized email message..."
            className="input-field"
            value={message}
            onChange={(e) => handleMessage(e.target.value)}
          />
        </InputWrapper>
      </Modal.Body>
      <Modal.Footer style={{ paddingBottom:'35px'}}>
        {isEmpty(props.pdfUrl) || props.docSend ? <Button className="modal-btn-x">{props.docSend ? 'Sending...' : 'Processing...'}</Button> :
        <Button className="modal-btn-x" onClick={ () => props.onSendDoc(emails, subject, message, props.pdfUrl) }>Send Document</Button> }
        <Button className="modal-btn-x" onClick={ () => props.setModalShow(false) }>Cancel</Button>
      </Modal.Footer>
    </Modal>
    </>
  );
}

const InputWrapper = styled.div`
  .input-field {
    width: 100%;
    border-color: hsl(0,0%,80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px; 
    color: #212e33;
    font-style: normal;
    min-height: 38px;
    padding: 10px 8px;
  }
`;

  
