import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import Cancel from '../../assets/cross.svg';
import styled from "styled-components";
import "./Modals.css";

export default function ExportToCsvModal(props) {
    
const [rows, setRows] = useState(20);

const handleValue = (value) => {
  setRows(value);
} 

return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      onHide={() => props.setModalShow(true)}
      centered
      style={{opacity:'1'}}
    >
      <Modal.Header style={{ paddingTop:'35px'}}>
      <img onClick={() => props.setModalShow(false)} src={Cancel} style={{width: "26px", position: 'absolute', right: '1.5rem', top: '1.5rem' , backgroundColor: 'transparent' , cursor: 'pointer', border: 'none'}}/>
        <Modal.Title id="contained-modal-title-vcenter">
          <p className="modal-title-x mb-0">CSV Export</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        <p>Export your ProDocs Documents table to a CSV File.<br/></p>
        <p>Enter the number of rows to export:</p>
        <InputWrapper>
            <input
              name={"rows"}
              placeholder="Enter Rows"
              className="input-field"
              value={rows}
              onChange={(e) => handleValue(e.target.value)}
            />
        </InputWrapper> 
        <p>(leave as blank to export all rows)</p>
      </Modal.Body>
      <Modal.Footer style={{ paddingBottom:'35px'}}>
        <Button className="modal-btn-x" onClick={ () => props.onGetUsers(rows) }>Export</Button>
        <Button className="modal-btn-x" onClick={ () => props.setModalShow(false) }>Cancel</Button>
      </Modal.Footer>
    </Modal>
  );
}

const InputWrapper = styled.div`
  .input-field {
    width: 100%;
    border-color: hsl(0,0%,80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px; 
    color: #212e33;
    font-style: normal;
    min-height: 38px;
    padding: 2px 8px;
  }
`;
  
