import React, { Component, useState, useEffect } from "react";
import styled from "styled-components";
import { Accordion, Card, Button, Table, Row, Col } from "react-bootstrap";
import Amplify, { API, graphqlOperation } from "aws-amplify";
import config from "../../aws-exports";
import {
  FaPlus,
  FaCalculator,
  FaTruck,
  FaArrowDown,
  FaEnvelope,
  FaEye,
  FaEdit,
  FaRegWindowClose,
} from "react-icons/fa";
import IconButton from "@material-ui/core/IconButton";
import ToggleIcon from "material-ui-toggle-icon";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Link, navigate } from "gatsby";
import { Menu, MenuItem, MenuButton, SubMenu } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import DownArrow from "../../assets/DownArrow.png";
import UpArrow from "../../assets/Uparrow.svg";
import EST from "../../assets/est.svg";
import WO from "../../assets/wo.svg";
import UP from "../../assets/arrow_up.svg";
import DOWN from "../../assets/arrow_down.svg";
import moment from "moment";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GeneralPopup from '../Modals/GeneralPopup';
import {
  faEdit,
  faDownload,
  faTrashAlt,
  faPencilAlt,
  faChevronCircleDown,
} from "@fortawesome/free-solid-svg-icons";
import { deleteDocumentWorkOrder, elasticSearchDeleteDocOrTemp } from "../../graphql/queries";
import  secureLocalStorage  from  "react-secure-storage";
import Sync from "../../assets/sync.gif";

Amplify.configure(config);

const options = ["None", "Atria"];
const ITEM_HEIGHT = 2;
export default function DocTableRow(props) {
  const {consoleLog} = require('../commonFunctions.js');
  // consoleLog('DocTableRow props: ', props)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const doc_row = props.doc_info;
  const index = props.index;
  const [isDocActive, setDocActive] = useState(false);
  const [state, setState] = useState({ on: false });
  const [showdivT, setShowdivT] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] =  React.useState(false);
  const [applyDocWorkOrderData, setApplyDocWorkOrderData] =  React.useState(0);

  const [synching, setSynching] = useState(false);
    const showSynching = async () => {
        setSynching(true);
        await new Promise((resolve) => setTimeout(resolve, 2000));  // Simulate save delay
        setSynching(false);
  };
 
  function onClickT() {
    setShowdivT(!showdivT);
    setState((state) => ({ on: !state.on }));
  }
  function editDoc(doc_type_id, templateId) {
    consoleLog("onClickT doc_type_id: ", doc_type_id);
    navigate(
      "/template?template_id=" + templateId + "&&doc_type_id=" + doc_type_id
    );
  }
  async function deleteDocWorkOrder(docWorkOrderInfo){ //it is being used for both Estimate / Work Order 
    consoleLog("deleteDocWorkOrder", docWorkOrderInfo.doc_id);
    showSynching();
    const delete_doc = await API.graphql({ // delete from DB
      query: deleteDocumentWorkOrder,
      variables: {
        doc_id: docWorkOrderInfo.doc_id,
      }
    });
    let res = JSON.parse(delete_doc.data.deleteDocumentWorkOrder);
    consoleLog('deleteDocWorkOrder>', res);
    consoleLog('deleteDocWorkOrder>', res.status);
    if(res.status==1){
      showSynching();
      const delete_es_doc = await API.graphql({ // delete from elasticsearch
        query: elasticSearchDeleteDocOrTemp,
        variables: {
          id: docWorkOrderInfo.doc_id,
          index: 'prodocs-documents'
        }
      });
      let es_result = JSON.parse(delete_es_doc.data.elasticSearchDeleteDocOrTemp);
        consoleLog('deleteDocWorkOrder>', es_result.status);
      if(es_result.status != 200){
        consoleLog('deleteDocWorkOrder handle_es_down status>', es_result.status);
        let es_down_docs = JSON.parse(secureLocalStorage.getItem("es_down_docs")).body.docs;
        es_down_docs.push({doc_id : docWorkOrderInfo.doc_id, es_down_flag : '3'});
        consoleLog('deleteDocWorkOrder handle_es_down es_down_docs>>>', es_down_docs);
        let temp_docs_temp = JSON.parse(secureLocalStorage.getItem("es_down_docs"));
        consoleLog('deleteDocWorkOrder handle_es_down temp_docs_temp>>>', temp_docs_temp);

        temp_docs_temp.body.docs = es_down_docs;
        consoleLog('deleteDocWorkOrder handle_es_down temp_docs_temp final>>>', temp_docs_temp);
        secureLocalStorage.setItem('es_down_docs', JSON.stringify(temp_docs_temp));
      }
      // Reload the current page after the row is deleted
      window.location.reload();

    }else{
      consoleLog('deleteDocWorkOrder>',res);
    }

  }

  return (
    <React.Fragment>
      {/* Delete Document Popup */}
      {
        showDeleteModal &&
        <GeneralPopup 
          titleText="Are You Sure?"
          bodyText={<>Are you sure you want to <b>delete</b> this document?</>}
          showModal={showDeleteModal}
          setShowModal={setShowDeleteModal}
          confirm={()=>{deleteDocWorkOrder(applyDocWorkOrderData)}}
        />
      }
      <tr
        key={doc_row.doc_id}
        className="vertical-middle"
        style={{ padding: "15px 0 15px 20px"}}
      >
        <td className="w-20 custom-padding-left-doc">
          {doc_row.doc_type_id == 1 && (
            <div className="d-flex align-items-center justify-content-start">
              <span className="estimate">
                {" "}
                <img src={EST} alt="" />{" "}
              </span>{" "}
              Estimate {/*doc_row.claim_number*/}
            </div>
          )}
          {doc_row.doc_type_id == 2 && (
            <div className="d-flex align-items-center justify-content-start">
              <span className="workorder">
                {" "}
                <img src={WO} alt="" />{" "}
              </span>{" "}
              Work Order
            </div>
          )}
        </td>
        <td className="w-35 ">
          <IconButton
            style={{ outline: "none", border: "none" }}
            className="p-1"
            onClick={onClickT}
          >
            <p style={{ color: "#333" }} className="my-0 text-left color-black">
              {doc_row.name} {/*doc_row.location*/}
            </p>
          </IconButton>
          {!showdivT ? null : (
            <div className="content ml-1">
              <div style={{display: 'flex', alignItems:'center'}}>
              <p
                className="text m-0"
                style={{
                  minWidth: '7.2em',
                  fontWeight: "500",
                  color: "#212E33",
                  fontFamily: "Roboto",
                }}
              >
                {" "}
                Created By:{" "}
              </p>
                <span
                  style={{ marginLeft: "10px", fontWeight: "normal" }}
                >
                  {doc_row.user_email}
                </span>{" "}
              </div>
              <div style={{display: 'flex', alignItems:'center'}}>
              <p
                className="text m-0"
                style={{
                  minWidth: '7.2em',
                  fontWeight: "500",
                  color: "#212E33",
                  fontFamily: "Roboto",
                }}
              >
                Address:{" "}
              </p>
                <span style={{ marginLeft: "10px", fontWeight: "normal" }}>
                  {doc_row.location}
                </span>{" "}
              </div>
              <div style={{display: 'flex', alignItems:'center'}}>
              <p
                className="text m-0"
                style={{
                  minWidth: '7.2em',
                  fontWeight: "500",
                  color: "#212E33",
                  fontFamily: "Roboto",
                }}
              >
                {" "}
                Created Date:{" "}
              </p>
                <span style={{ marginLeft: "10px", fontWeight: "normal" }}>
                  {moment(doc_row.date_created).utc().format("MMMM Do YYYY hh:mm a")}
                </span>{" "}
              </div>
              <div style={{display: 'flex', alignItems:'center'}}>
              <p
                className="text m-0"
                style={{
                  minWidth: '7.2em',
                  fontWeight: "500",
                  color: "#212E33",
                  fontFamily: "Roboto",
                }}
              >
                {" "}
                Claim / Ref #:{" "}
              </p>
                <span
                  style={{ marginLeft: "10px", fontWeight: "normal" }}
                >
                  {doc_row.ref_id} / {doc_row.claim_number}
                </span>{" "}
              </div>
            </div>
          )}
        </td>

        <td className="w-25 px-lg-0 px-md-0 px-sm-2">
          {moment(doc_row.date_updated).utc().format("MMMM Do YYYY hh:mm a")}
        </td>
        <td className="w-20">
          <Check>
            <div className="table_icons">
              <DropdownButton className={isDocActive ? "box-m-btn-active" : "box-m-btn"} title='...'  onClick={()=> setDocActive(!isDocActive)} onBlur={()=> setDocActive(false)}>
                <div>
                <Dropdown.Item
                  href={`/template?template_id=${doc_row.template_id}&&doc_type_id=${doc_row.doc_type_id}&&doc_id=${doc_row.doc_id}`}
                  className="custom-li-font w-100"
                >
                  <span className="link-text">Edit</span>
                </Dropdown.Item>
                {doc_row.doc_type_id === 1 ?
                  <Dropdown.Item
                    href={`/pdftable?preview=estpdf&doc_id=${doc_row.doc_id}`}
                    className="custom-li-font w-100"
                  >
                    <span className="link-text">Preview Estimate</span>
                  </Dropdown.Item>
                :
                  <Dropdown.Item
                    href={`/materialCostPdf?preview=wrkpdf&doc_id=${doc_row.doc_id}`}
                    className="custom-li-font w-100"
                  >
                    <span className="link-text">Preview Work Order</span>
                  </Dropdown.Item>
                }
                {doc_row.doc_type_id === 1 ?
                  <Dropdown.Item
                    href={`/pdftable?invoice=1&preview=estpdf&doc_id=${doc_row.doc_id}`}
                    className="custom-li-font w-100"
                  >
                    <span className="link-text">Preview Invoice</span>
                  </Dropdown.Item>
                :
                  <Dropdown.Item
                    href={`/materialCostPdf?invoice=1&preview=wrkpdf&doc_id=${doc_row.doc_id}`}
                    className="custom-li-font w-100"
                  >
                    <span className="link-text">Preview Material Cost</span>
                  </Dropdown.Item>
                }
                <Dropdown.Item>
                    <a className="custom-li-font w-100" href="javascript:;" onClick={e => props.downloadPdf(doc_row.doc_id, doc_row.doc_type_id, 0, 0, doc_row.pdf)}><span className="link-text">Download</span></a>
                </Dropdown.Item>
                  <Dropdown.Item>
                    <a className="custom-li-font w-100" href="javascript:;" onClick={e => props.emailPopup(doc_row.doc_id, doc_row.doc_type_id, doc_row.pdf)}><span className="link-text">Send Email</span></a>
                  </Dropdown.Item>
                <Dropdown.Item
                  onClick={()=>{setShowDeleteModal(true);setApplyDocWorkOrderData(doc_row);}}
                  className="custom-li-font w-100"
                >
                  <span className="link-text">Delete</span>
                </Dropdown.Item>
              </div>
              </DropdownButton>
              <IconButton
                style={{ outline: "none", border: "none", marginLeft: "25px" }}
                className="p-1"
                onClick={onClickT}
              >
                <ToggleIcon
                  on={state.on}
                  onIcon={ <img src={UP} alt="" /> }
                  offIcon={ <img src={DOWN} alt="" /> }
                />
              </IconButton>
            </div>
          </Check>
        </td>
      </tr>
      {synching && <img className="sync" src={Sync} />}
    </React.Fragment>
  );
}

const Major = styled.div`
  width: 100%;
`;

const DocRow = styled.div`
  .w-15 {
    width: 15% !important;
  }
  .w-20 {
    width: 20% !important;
  }
  .w-25 {
    margin-left: 3px !important;
    width: 25% !important;
  }
  .w-35 {
    width: 35%!important;
  }
  .w-40 {
    width: 40% !important;
  }
  p {
    color: #333;
  }
  .color-black {
    color: #333;
  }
  td {
    height: 5.15%;
  }
`;
const Check = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 20px;
  
  .table_icons {
    .szh-menu-container {
      
      @media(min-width: 768px){
        position: absolute !important; 
        right: 100px;
      }
      @media(min-width: 1024px){
        right: 80px;
      }
    }
    .szh-menu__item:active {
      background: #f7941c !important;
    }
    .szh-menu {
      left: -228px !important;
      top: -18px !important;
      margin-bottom: 15px;
      width: 180px;
      min-height: 100px;
      padding: 0;
      box-shadow: 0 2px 5px 0 rgba(0,0,0,0.3);
      border-radius: 3px;
      .szh-menu__item {
        padding: 0;
      }
    }
    .dropdown-menu {
      inset: unset !important;
      background: white !important;
      min-width: 180px !important;
      padding: 0;
      transform: translate(-200px, -47px) !important;
    }
    .dropdown-item {
      width: 180px !important;
      padding: 0;
    }
    .dropdown-item:hover {
      background: #ebebeb !important;
    }
    .dropdown-menu:before {
      display: none !important;
    }
    .dropdown-menu:after {
      display: none !important;
    }
    .box-m-btn {
      height: 36px;
      width: 36px;
      border-radius: 3px;
      .dropdown-toggle {
        display: flex;
        justify-content: center;
        font-weight: 600;
        font-size: 18px;
        color: black;
        border: none;
        box-shadow: unset;
        background-color: #d6e1e5 !important;
        margin : 0;
        line-height: 1;
        height: 36px;
        width: 36px;
      }
    }
    .custom-li-font {
      text-decoration: none;
      font-family: Roboto;
      color: #737f82 !important;
    }
    .link-text {
	  margin: 15px;
	  line-height: 2.6;
	}
    .box-m-btn-active {
      height: 36px;
      width: 36px;
      border-radius: 3px;
      .dropdown-toggle {
        display: flex;
        justify-content: center;
        font-weight: 600;
        font-size: 18px;
        color: black;
        border: none;
        box-shadow: unset;
        background-color: #f7941c !important;
        margin : 0;
        line-height: 1;
        height: 36px;
        width: 36px;
      }
    }
  }
`;
